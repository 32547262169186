import React, { useEffect, useState } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as RedirectIcon } from "../../../assets/icons/external-link.svg";
import { ReactComponent as PlayIcon } from "../../../assets/Images/PlayIcon.svg";
import { ReactComponent as DotIcon } from "../../../assets/Images/Text.svg";
import { PAGE_SIZE_50 } from "../CommonComponents/Common";
import DynamicTable from "../CommonComponents/Table/DynamicTable";
import { lastCampaignActionList } from "../ProjectSection/CampaignSection/Store/Campaign.action";
import { swalWarningMessage } from "../../../Constant/swalMessage";

interface CampaignData {
  campaignName: string;
  lastCampaignRun: string;
  projectName: string;
  completed: boolean;
  redial: boolean;
  suspended: boolean;
  _id: boolean;
}

const formattedDate = (date) =>
  moment(date, "ddd, MMM D, YYYY hh:mm A").format("YYYY-MM-DD");
const formattedTime = (date) =>
  moment(date, "ddd, MMM D, YYYY hh:mm A").format("hh:mm a");

const CampaignList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [lastCampaignListData, setLastCampaignListData] =
    useState<CampaignData[]>();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getCampaignList();
  }, [currentPage]);

  const getCampaignList = async () => {
    const obj = {
      pageIndex: currentPage,
      pageSize: PAGE_SIZE_50,
    };
    setLoading(true);
    const res: any = await dispatch(lastCampaignActionList(obj));
    if (res.length > 0) {
      setLastCampaignListData(res);
      setTotalPages(Math.ceil(res?.length / PAGE_SIZE_50));
    }
    setLoading(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleRedirect = (campData) => {
    if (campData?.isDeleted) {
      swalWarningMessage("Project is Deleted.");
      return;
    } else if (campData?.campaignDeleted) {
      swalWarningMessage("Campaign is deleted.");
      return;
    } else {
      history.push(
        `/account/project/${campData?.projectId}/campaign-runs?campaignid=${campData?._id}`
      );
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid #E0E0E0",
        borderRadius: 2,
        padding: 2,
      }}
    >
      <Box mb={1}>
        <Typography
          fontSize={14}
          fontWeight={500}
          color="#555555"
          marginBottom={2}
        >
          Overall Campaign List
        </Typography>
      </Box>
      <DynamicTable
        columns={[
          {
            label: "Campaign Name",
            field: "campaignName",
            styles: { textTransform: "capitalize" },
          },
          {
            label: "Last campaign Run",
            render: (campData) => {
              return (
                <span>
                  <span className="mr-1">{`${formattedDate(
                    campData?.lastCampaignRun
                  )}`}</span>
                  <DotIcon />
                  <span className="ml-1">{`${formattedTime(
                    campData?.lastCampaignRun
                  )}`}</span>
                </span>
              );
            },
          },
          {
            label: "ProjectName",
            render: (campData) => {
              if (campData?.isDeleted) {
                return campData?.projectName;
              }
              return (
                <Link
                  href={`/account/project/${campData?.projectId}/agents`}
                  rel="noopener noreferrer"
                  underline="hover"
                  color="#181818"
                >
                  {campData?.projectName || "No Project Name"}
                </Link>
              );
            },
            styles: { textTransform: "capitalize" },
          },
          {
            label: "Actions",
            render: (row) => (
              <Box py={0.5}>
                <Button
                  startIcon={<PlayIcon />}
                  sx={{
                    color: "#333333",
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                >
                  Start Campaign
                </Button>
                <Button
                  endIcon={<RedirectIcon />}
                  sx={{
                    color: "#666",
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                  onClick={() => handleRedirect(row)}
                >
                  View List
                </Button>
              </Box>
            ),
          },
        ]}
        data={lastCampaignListData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        noDataAvailableMessage="No Campaigns  Available"
      />
    </Box>
  );
};

export default CampaignList;
