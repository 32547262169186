import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import authStyles from "./styles";
import eye from "../../../assets/icons/eye.svg";
import eyeSlash from "../../../assets/icons/eye-slash.svg";
import { useDispatch } from "react-redux";
import { registerNewAccountAction } from "../Actions/CommonActions";
import { useHistory } from "react-router-dom";
import arrowBackIcon from "../../../assets/icons/arrow-back.svg";
import { LoadingSlides } from "../CommonComponents/Common";

const SignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [signUpData, setSignUpData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [spin, setSpin] = useState(false);
  const { email, password, confirmPassword } = signUpData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignUpData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTogglePassword = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSignUp = async (e) => {
    setSpin(true);
    e.preventDefault();

    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+[\]{}|\\:;'"<>,.?/~`]/.test(password)
    ) {
      if (password === confirmPassword) {
        const res = await dispatch(registerNewAccountAction(signUpData));
        if (res !== undefined) {
          history.push("/login");
        }
      }
    }
    setSpin(false);
  };

  return (
    <Box className="main-container">
      <Typography color="#656E7F" fontSize={14} fontWeight={500}>
        Welcome!
      </Typography>
      <Typography fontSize={28} fontWeight={700} mb={1} color="#1A1D23">
        Sign Up with your email!
      </Typography>

      <Box mb={2}>
        <Typography {...authStyles.label}>Email</Typography>
        <TextField
          name="email"
          value={email}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          placeholder="Enter your email"
          sx={authStyles.textInput}
        />
      </Box>

      <Box mb={2}>
        <Typography {...authStyles.label}>Password</Typography>
        <TextField
          type={showPassword.password ? "text" : "password"}
          name="password"
          value={password}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          placeholder="Enter your password"
          sx={authStyles.textInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleTogglePassword("password")}
                  edge="end"
                  sx={{ padding: "0", marginRight: 1.5 }}
                  disableRipple
                  disableFocusRipple
                  style={{ outline: "none" }}
                >
                  <img
                    src={showPassword.password ? eyeSlash : eye}
                    alt="password visibility"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box mb={2}>
        <Typography {...authStyles.label}>Confirm Password</Typography>
        <TextField
          type={showPassword.confirmPassword ? "text" : "password"}
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          placeholder="Confirm your password"
          sx={authStyles.textInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleTogglePassword("confirmPassword")}
                  edge="end"
                  sx={{ padding: "0", marginRight: 1.5, outline: "none" }}
                  disableRipple
                  disableFocusRipple
                >
                  <img
                    src={showPassword.confirmPassword ? eyeSlash : eye}
                    alt="password visibility"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Button
        onClick={handleSignUp}
        disabled={spin}
        variant="contained"
        sx={authStyles.loginButton}
        fullWidth
      >
        {spin ? <LoadingSlides bgColor="white" /> : "Sign Up"}
      </Button>

      <Typography
        color="#1A1D23"
        fontSize={14}
        fontWeight={400}
        textAlign="left"
        mt={3}
        pr={2}
      >
        By continuing you agree to Vodex{" "}
        <span style={{ color: "#4280FF" }}>Terms of Service</span> and{" "}
        <span style={{ color: "#4280FF" }}>Privacy Policy</span>
      </Typography>
      <Typography fontSize={14} fontWeight={400} textAlign="center" mt={5}>
        <Box component={"span"}
          onClick={() => history.push("/sigin")}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            color: "#1A1D23",
            gap:1,
            cursor:"pointer"
          }}
        >
          <img
            src={arrowBackIcon}
            alt="arrow back"
            style={{ marginRight: 4 }}
          />
          Go Back to login
        </Box>
      </Typography>
    </Box>
  );
};

export default SignUp;
