import React from "react";
import { Box, Typography } from "@mui/material";

const SummaryCard = ({ title, value, circleColor }) => {
  return (
    <Box
      sx={{
        minHeight: 100,
        padding: 2,
        borderRadius: 2,
        border: "1px solid #D1D9EF",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 1,
        }}
      >
        <Box
          sx={{
            width: 12,
            height: 12,
            background: circleColor,
            borderRadius: "50%",
            marginRight: 1,
          }}
        />
        <Typography color="#555555" fontSize={14} fontWeight={500}>
          {title}
        </Typography>
      </Box>

      <Typography
        color="#181818"
        fontSize={16}
        fontWeight={700}
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default SummaryCard;
