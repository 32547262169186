import React, { useEffect, useState } from "react";
import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import { isEmpty, PAGE_SIZE_50 } from "../CommonComponents/Common";
import NewFilterDrawerBody from "../ProjectSection/CampaignSection/CampaignDetails/NewFilterBody";
import {
  getCallHistoryAction,
  getCallHistoryCountAction,
} from "../ProjectSection/CampaignSection/Store/Campaign.action";
import CallHistoryHeader from "./CallHistoryHeader";
import { ReactComponent as RedirectIcon } from "../../../assets/icons/external-link.svg";
import chroma from "chroma-js";
import CallTranscriptModal from "./CallTranscriptModal";
import DynamicTable from "../CommonComponents/Table/DynamicTable";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CallHistoryPopover from "./CallHistoryPopover";
import CallLogs from "../ProjectSection/CampaignSection/CallHistory/CallLogs";

const CallHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = moment().format("YYYY-MM-DD");
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let Stalker = locSearch.get("stalker");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showCallTranscriptModal, setShowCallTranscriptModal] = useState(false);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [searchText, setSearchText] = useState("");

  let filterData: any = localStorage.getItem("callHistoryFilterData");
  let filterParsed = JSON.parse(filterData);
  const [dateRange, setDateRange] = useState([
    new Date(filterParsed?.startDate || currentDate),
    new Date(filterParsed?.endDate || currentDate),
  ]);
  const [startDate, endDate] = dateRange;
 

  const appliedFiltersLoc: any = localStorage?.getItem("savedFilters");
  const appliedFilters = JSON.parse(appliedFiltersLoc);

  const [applied, setApplied] = useState({
    durationFilterVal: appliedFilters?.durationFilter || "",
    outComefilterVal: appliedFilters?.outcomeFilters || [],
    selectedProjectVal: appliedFilters?.selectedProjectId || "",
    statusFilterVal: appliedFilters?.statusFilter || [],
    customStatusVal: appliedFilters?.customFilter || "",
  });
  const [call_uuid, setCall_uuid] = useState("");
  const [show, setShow] = useState(false);

  const {
    durationFilterVal,
    outComefilterVal,
    selectedProjectVal,
    statusFilterVal,
    customStatusVal,
  } = applied;

  const reducer: any = useSelector((state) => state);
  const {
    AccountReducer: { constantData, selectedAccount },
    CampaignReducer: { callHistoryDetails, callHistoryDetailsTotalCounts },
  } = reducer;

  const [state, setState] = useState<any>({
    filterDrawer: false,
    trackEnabledRecording: [],
    pageIndex: currentPage,
    durationFilter: durationFilterVal || "",
    statusFilter: statusFilterVal || [],
    outComefilter: outComefilterVal || [],
    min: customStatusVal.split("-")[0] || "",
    max: customStatusVal.split("-")[1] || "",
    selectedProjectId: selectedProjectVal || "",
    filterName: "",
    campaignDataList: [],
  });
  const {
    filterDrawer,
    trackEnabledRecording,
    pageIndex,
    durationFilter,
    statusFilter,
    outComefilter,
    selectedProjectId,
    filterName,
    campaignDataList,
  } = state;

  useEffect(() => {
    fetchCallHistory();
  }, [currentPage, searchText]);

  useEffect(() => {
    if (callHistoryDetailsTotalCounts) {
      setTotalPages(Math.ceil(callHistoryDetailsTotalCounts / PAGE_SIZE_50));
    }
  }, [currentPage, searchText, callHistoryDetailsTotalCounts]);

  useEffect(() => {
    if (callHistoryDetails) {
      setState((prev) => ({
        ...prev,
        campaignDataList: callHistoryDetails?.map((item) => item?.combinedData),
      }));
    }
  }, [callHistoryDetails]);

  const fetchCallHistory = async () => {
    setLoading(true);
    let obj = {
      pageIndex: currentPage,
      pageSize: PAGE_SIZE_50,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0] || durationFilterVal?.split(" ")[0],
      callStatus: statusFilter || statusFilterVal,
      outcome: outComefilter || outComefilterVal,
      searchText: searchText,
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
      projectId: selectedProjectId || selectedProjectVal,
    };

    await dispatch(getCallHistoryAction(obj));
    setLoading(false);
    await dispatch(getCallHistoryCountAction(obj));
  };

  const openCallTranscriptModal = (transcriptData) => {
    setSelectedTranscript(transcriptData);
    setShowCallTranscriptModal(true);
  };

  const closeCallTranscriptModal = () => {
    setShowCallTranscriptModal(false);
    setSelectedTranscript(null);
  };

  const handleRedirect = (callData) => {
    if (callData?.isDeleted) {
      swalWarningMessage("Project is Deleted.");
      return;
    } else if (callData?.campaignDeleted) {
      swalWarningMessage("Campaign is deleted.");
      return;
    } else {
      history.push(
        `/account/project/${callData?.projectId}/campaign-runs?campaignid=${callData?.campaignId}&campdate=${callData?.createdAt}`
      );
    }
  };

  const exportToCSV = async () => {
    let obj = {
      pageIndex: currentPage,
      pageSize: PAGE_SIZE_50,
      searchText: searchText,
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
      all: true,
    };
    let res: any = await dispatch(getCallHistoryAction(obj));
    let matchArr = [
      "mandatory_params",
      "firstName",
      "lastName",
      "FirstName",
      "LastName",
      "phone",
      "PhoneNum",
    ];

    if (res) {
      let arr: any = [];
      res?.forEach((kl) => {
        let callHistory = kl?.combinedData?.campaignRunDetails;
        let copy = callHistory?.customParameters;
        let obj: any = {};
        obj["First Name"] = copy?.FirstName || "--";
        obj["Last Name"] = copy?.LastName || "--";
        obj["Phone number"] = callHistory?.phone || "--";
        obj["Call UUID"] = callHistory?.call_uuid || "";
        obj["Calling Status"] =
          constantData?.callStatus[callHistory?.callStatus] || "";
        obj["Cost"] = callHistory?.newCost || "";
        if (selectedAccount?.params?.pricesWaivers) {
          obj["Cost"] = "--";
        }
        obj["Recording Url"] = callHistory?.callRecordingUrl || "";
        if (!isEmpty(copy)) {
          for (const customKeys in copy) {
            if (!matchArr.includes(customKeys)) {
              obj[customKeys] = copy[customKeys];
            }
          }
        }
        arr.push(obj);
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(arr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Call History Report` + fileExtension);
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const toggle = () => {
    setState((prev) => ({
      ...prev,
      filterDrawer: !prev.filterDrawer,
    }));
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const obj = {
    setMainState: setState,
    trackEnabledRecording,
    mainState: state,
    startDate,
    endDate,
  };

  return (
    <Box sx={{ padding: 1 }}>
      <CallHistoryHeader
        setSearchText={setSearchText}
        exportToCSV={exportToCSV}
        toggleFilterDrawer={toggle}
        totalCalls={callHistoryDetailsTotalCounts}
      />
      <DynamicTable
        columns={[
          {
            label: "Phone Number",
            render: (row) => {
              return row?.campaignRunDetails?.phone;
            },
          },
          {
            label: "Call Status",
            render: (row) => {
              const statusColor =
                constantData["callStatusColour"][
                  row?.campaignRunDetails?.callStatus
                ] || "#333333";

              const lighterColor = chroma(statusColor).alpha(0.1).hex();

              return (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "capitalize",
                    borderRadius: 2,
                    backgroundColor: lighterColor,
                    border: "none",
                    color: statusColor,
                    "&:hover": {
                      cursor: "default",
                      backgroundColor: lighterColor,
                      border: "none",
                      color: statusColor || "#333333",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      backgroundColor: statusColor || "#333333",
                      mr: 0.75,
                    }}
                  />
                  {
                    constantData["callStatus"][
                      row?.campaignRunDetails?.callStatus
                    ]
                  }
                </Button>
              );
            },
            styles: { textAlign: "left" },
          },
          {
            label: "Cost",
            render: (row) => {
              return row?.campaignRunDetails?.newCost;
            },
          },
          {
            label: "UUID",
            render: (row) => {
              return row?.campaignRunDetails?.call_uuid;
            },
          },
          {
            label: "Recording",
            render: (row) => (
              <Box display="flex" alignItems="center">
                <IconButton>
                  <PlayCircleOutlineIcon />
                </IconButton>
                <Typography variant="caption">
                  {row?.campaignRunDetails?.duration}
                </Typography>
              </Box>
            ),
          },
          {
            label: "Actions",
            render: (row) => (
              <>
                <Button
                  color="primary"
                  sx={{
                    color: "#4280FF",
                    fontSize: 11,
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                  onClick={() =>
                    openCallTranscriptModal(row?.campaignRunDetails)
                  }
                >
                  View Transcript
                </Button>
                <CallHistoryPopover callData={row} />

                <IconButton onClick={() => handleRedirect(row)}>
                  <RedirectIcon />
                </IconButton>
                {constantData?.logAccess?.includes(Stalker) && (
                  <button
                    className="custom-button-icon"
                    title="Logs"
                    onClick={() => {
                      setCall_uuid(row?.campaignRunDetails?.call_uuid);
                      setShow(true);
                    }}
                  >
                    Logs
                  </button>
                )}
              </>
            ),
          },
        ]}
        data={campaignDataList}
        loading={loading}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        noDataAvailableMessage="No Call History Available"
      />

      {showCallTranscriptModal && (
        <CallTranscriptModal
          show={showCallTranscriptModal}
          onClose={closeCallTranscriptModal}
          selectedTranscript={selectedTranscript}
        />
      )}

      {filterDrawer && (
        <Drawer anchor={"right"} open={filterDrawer} onClose={toggle}>
          {<NewFilterDrawerBody obj={obj} all={true} main={true} />}
        </Drawer>
      )}
      {call_uuid && (
        <CallLogs
          call_uuid={call_uuid}
          show={show}
          toggle={() => {
            setCall_uuid("");
            setShow(false);
          }}
        />
      )}
    </Box>
  );
};

export default CallHistory;
