import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/Images/SearchIcon.svg";
import { ReactComponent as ExportIcon } from "../../../assets/icons/export.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/sort.svg";
import useDebounce from "../../Hooks/useDebounce";

const CallHistoryHeader = ({ setSearchText, exportToCSV, toggleFilterDrawer, totalCalls }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    setSearchText(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <Stack
    direction="row"
    spacing={2}
    justifyContent="space-between"
    sx={{ marginBottom: 2 }}
  >
    <Box display="flex" justifyContent="center" alignItems="center" gap={2} >
      <Typography color="#181818" fontSize={20} fontWeight={600}>
        Call History
      </Typography>
      <Typography
        color="#555555"
        component="span"
        fontSize={14}
        fontWeight={500}
        border={1}
        borderColor="#CCD2DE"
        px={1.5}
        py={0.25}
        borderRadius="9px"
      >
        Total calls{" "}
        <span style={{ color: "#55555599", fontSize: 20, fontWeight: 700 }}>
          {totalCalls || 0}
        </span>
      </Typography>
    </Box>
    <Box display="flex" gap={2}>
      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root": {
            pl: 0.5,
            borderRadius: 2,
          },
          "& .MuiOutlinedInput-input": {
            padding: 0.75,
            "&::placeholder": {
              color: "#9E9FA1",
              fontSize: 14,
              fontWeight: 400,
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchIcon color="#555555" height={14} width={14} />
            </IconButton>
          ),
        }}
      />
      <Button
        startIcon={<ExportIcon height={14} width={14} />}
        onClick={exportToCSV}
        variant="outlined"
        sx={{
          color: "#555555",
          borderColor: "#CCD2DE",
          backgroundColor: "transparent",
          textTransform: "capitalize",
          fontSize: 12,
          fontWeight: 500,
          px: 2,
          py: 0,
        }}
      >
        Export
      </Button>
      <Button
        onClick={toggleFilterDrawer}
        variant="outlined"
        sx={{
          px: 1,
          py: 0,
          borderRadius: 2,
          minWidth: "auto",
          borderColor: "#CCD2DE",
        }}
      >
        <FilterIcon />
      </Button>
    </Box>
  </Stack>
  );
};

export default CallHistoryHeader;
