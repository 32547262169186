import { Route, RouteProps } from "react-router";
import React from "react";
import NewLogin from "../../components/NewLogin/NewLogin";
import Login from "../../components/NewDevelopment/LoginRegister/Login";
import OnboardingPage from "../../components/NewDevelopment/LoginRegister/OnboardingPage";
import { isVodexLoggedin } from "../../components/NewDevelopment/CommonComponents/Common";
import AuthLayout from "../../components/NewDevelopment/auth/AuthLayout";
import NewLoginPage from "../../components/NewDevelopment/auth/Login";

export function AccountRoute({ children, ...rest }: RouteProps): JSX.Element {
  return (
    <Route
      {...rest}
      render={() =>
        isVodexLoggedin() ? (
          <AuthLayout>
            <NewLoginPage />
          </AuthLayout>
        ) : (
          <NewLogin />
        )
      }
    />
  );
}
