import {
  API,
  BASE_URL_AI,
  BASE_URL_API_V1,
  axiosClient,
} from "../../../Constant/apiConstant";

export const getCampaignService = async (id, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN}/${id}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getCallHistoryService = async (obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CALL_HISTORY}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getCampaignCountService = async (id, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN_COUNT}/${id}`,
      { params: { ...obj } }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getCallHistoryCountService = async (obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CALL_HISTORY_COUNT}`,
      { params: { ...obj } }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveCampaignService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteCampaignService = async (id) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const triggerCampaignService = async (data, postObj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.TRIGGER_CAMPAIGN}/${data.projectId}/${data.campaignId}`,
      { ...postObj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const checkForCampaignValidationService = async (id, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CHECK_CAMPAIGN_VALIDATION}/${id}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCampaignRunsService = async (id, campId, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN_RUNS}/${id}/${campId}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCampaignRunsByIdService = async (id, campId, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN_RUNS}/${id}/${campId}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getCampaignRunsCountService = async (id, campId) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN_RUNS_COUNT}/${id}/${campId}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const stopCampaignRunService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.STOP_CAMPAIGN_RUNS}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveScheduleCampaignService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.SCHEDULE_CAMPAIGN}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getScheduleCampaignService = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.SCHEDULE_CAMPAIGN}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const deleteScheduleCampaignService = async (id) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.SCHEDULE_CAMPAIGN}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCampaignDetailsService = async (projectId, id, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN_DETAILS}/${projectId}/${id}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getExportCampaignService = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.EXCEL_EXPORT}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveFilteredCampaignService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.FILTERED_CAMPAIGN}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addManualAudienceExcel = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.SAVE_MANUAL_FIELDS}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const checkPromptService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AI.CHECK_PROMPT}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getExistingExcelService = async (data) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.EXISTING_EXCEL}/${data.projectId}/${data.campaignId}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveInsightsCampaignService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.SAVE_INSIGHTS}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getInsightsCampaignService = async (campaignId) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.SAVE_INSIGHTS}/${campaignId}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAllCampaignDetailsService = async (campaignId, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.ALL_DETAILS}/${campaignId}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const checkQueueService = async (gateway) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.AI.QUEUE}/${gateway}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCallLogsService = async (call_uuid) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.AI.LOGS}/${call_uuid}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const favouriteCampaignService = async (data, id) => {
  try {
    let res = await axiosClient.patch(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN}/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "favouriteCampaignService-Service");
  }
};

export const getCampaignRunsLiveService = async (id, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN_RUNS_LIVE}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const increaseCpsService = async (data, gateway) => {
  try {
    let res = await axiosClient.patch(
      `${BASE_URL_AI}${API.V1.AI.INCREASE_CPS}/${gateway}`,
      { ...data }
    );
    return res?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCpsValueService = async (gateway) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.AI.INCREASE_CPS}/${gateway}`
    );
    return res?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCampaignDetailsByIdService = async (projectId, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.CAMPAIGN}/${projectId}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const increaseCapacityService = async (data, gateway) => {
  try {
    let res = await axiosClient.patch(
      `${BASE_URL_AI}${API.V1.AI.INCREASE_CAPACITY}/${gateway}`,
      { ...data }
    );
    return res?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const attchReferenceService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.REFRENCE_ADD}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const clearQueueService = async (data) => {
  console.log(`${BASE_URL_AI}${API.V1.AI.CLEAR_QUEUE}`)
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AI.CLEAR_QUEUE}`,
      { ...data }
    );
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const gatewaySwitchService = async (key, gateway) => {
  console.log(`${BASE_URL_AI}${API.V1.AI.SWITCH_GATEWAY}/${key}/${gateway}`)
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AI.SWITCH_GATEWAY}/${key}/${gateway}`,
    );
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const lastCampaignRunListService = async (obj) => {
  try {
    let res = await axiosClient.get(`${BASE_URL_API_V1}/last-campaignRun`, {
      params: obj,
    });
    return res?.data;


  } catch (error) {
    console.log(error);
    throw error;
  }
};