import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import authStyles from "./styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import arrowBackIcon from "../../../assets/icons/arrow-back.svg";
import { changePasswordAction } from "../Actions/CommonActions";
import { swalErrorMessage } from "../../../Constant/swalMessage";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [spin, setSpin] = useState(false);
  const location = useLocation();
  const [errors, setErrors] = useState(false);
  const locSearch = new URLSearchParams(location.search);
  let token = locSearch.get("token");
  
  const [state, setState] = useState<any>({
    password: "",
    cpassword: "",
  });
  const { password,  cpassword} = state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    setSpin(true);
    // console.log("password", password);
    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+[\]{}|\\:;'"<>,.?/~`]/.test(password)
    ) {
      if (password === cpassword) {
        let obj = {
          emailToken: "qf9v27unrx",
          password: password,
        };

        let res: any = await dispatch(changePasswordAction(obj));
        console.log("res", res)
        if (res) {
          history.push("/");
        }
      } else {
        swalErrorMessage("Password didn't match");
      }
    } else {
      setErrors(true);
    }

    setSpin(false);
  };

  const handleResetPassword = () => {};
  return (
    <Box className="main-container">
      <Typography fontSize={28} fontWeight={700} color="#1A1D23">
        Reset Password
      </Typography>
      <Box mb={2}>
        <Typography {...authStyles.label}>Password</Typography>
        <TextField
          name="password"
          value={password}
          type="password"
          onChange={handleChange}
          variant="outlined"
          fullWidth
          placeholder="Enter your new password"
          sx={authStyles.textInput}
        />
      </Box>
      <Box mb={1}>
        <Typography {...authStyles.label}>Confirm Password</Typography>
        <TextField
          name="cpassword"
          value={cpassword}
          onChange={handleChange}
          type={"password"}
          variant="outlined"
          fullWidth
          placeholder="Confirm your password"
          sx={authStyles.textInput}
        />
      </Box>
      {errors && (
              <p style={{ color: "red" }}>
                Password must be at least 8 characters and must contain an
                uppercase, lowercase,numbers and special characters
              </p>
            )}
      <Button
        onClick={resetPassword}
        disabled={spin}
        variant="contained"
        sx={authStyles.loginButton}
        fullWidth
      >
        Reset Password
      </Button>
      <Typography fontSize={14} fontWeight={400} textAlign="center" mt={3}>
        <Link
          to="/login"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            color: "#1A1D23",
          }}
        >
          <img
            src={arrowBackIcon}
            alt="arrow back"
            style={{ marginRight: 4 }}
          />
          Back to login
        </Link>
      </Typography>
    </Box>
  );
};

export default ResetPassword;
