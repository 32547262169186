import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import arrowBackIcon from "../../../assets/icons/arrow-back.svg";
import emailSuccessIcon from "../../../assets/icons/email-success.svg";
import authStyles from "./styles";
import { useDispatch } from "react-redux";
import { resetPasswordAction } from "../Actions/CommonActions";
import { swalErrorMessage } from "../../../Constant/swalMessage";
import { useHistory } from "react-router-dom";
import { LoadingSlides } from "../CommonComponents/Common";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [spin, setSpin] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setSpin(true);
    if (email) {
      await dispatch(resetPasswordAction({ email: email.trim() }));
      setEmailSent(true);
    } else {
      swalErrorMessage("Please enter a valid email");
    }
    setSpin(false);
  };

  return (
    <Box className="main-container">
      {emailSent ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={emailSuccessIcon}
            alt="email success"
            height={60}
            width={60}
          />
          <Typography
            color="#1C203E"
            fontSize={18}
            fontWeight={700}
            gutterBottom
          >
            Verification Email Sent
          </Typography>
          <Typography
            color="#4D5564"
            fontSize={13}
            fontWeight={400}
            gutterBottom
          >
            We sent a password reset mail to{" "}
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {email}
            </span>
          </Typography>
          <Button
            sx={{
              mt: 1.5,
              color: "#FF5E00",
              fontSize: 13,
              fontWeight: 500,
            }}
          >
            Check your Mail
          </Button>
        </Box>
      ) : (
        <>
          <Typography fontSize={28} fontWeight={700} color="#1A1D23">
            Forgot Password?
          </Typography>
          <Typography color="#656E7F" fontSize={14} fontWeight={500} mb={2}>
            No worries we will send you a reset link
          </Typography>
          <Box mb={2}>
            <Typography {...authStyles.label}>Email</Typography>
            <TextField
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              fullWidth
              placeholder="Enter your registered email"
              sx={authStyles.textInput}
            />
          </Box>

          <Button
            onClick={handleResetPassword}
            disabled={spin}
            variant="contained"
            sx={authStyles.loginButton}
            fullWidth
          >
            {spin ? <LoadingSlides bgColor="white"/> : "Reset Password"}
          </Button>
          <Typography fontSize={14} fontWeight={400} textAlign="center" mt={3}>
            <Box component={"span"}
              onClick={() => history.push("/login")}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "none",
                color: "#1A1D23",
                cursor:"pointer"
              }}
            >
              <img
                src={arrowBackIcon}
                alt="arrow back"
                style={{ marginRight: 4 }}
              />
              Back to login
            </Box>
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ForgotPassword;
