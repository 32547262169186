import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "../../../assets/Images/CalendarIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";

const Heading = ({ projectList, state, setState }) => {
  const {selectedProject,startDate,endDate} = state;
  const [dateRange, setDateRange] = useState([null, null]) as any;
  const [selectedprojectId, setSelectedProjectId] = useState(selectedProject);
  const [startingDate, endingDate] = dateRange;
  const projects = projectList.map((project) => ({
    projectName: project.projectName,
    _id: project._id,
  }));

  const applyFilters = () => {
    const [newStartDate, newEndDate] = dateRange;

    setState((prev) => ({
      ...prev,
      selectedProject: selectedprojectId,
      startDate: newStartDate ? newStartDate.toISOString() : "",
      endDate: newEndDate ? newEndDate.toISOString() : "",
    }));
  };
  const resetFilters = () => {
    setDateRange([null, null]);
    setSelectedProjectId("");
  };
  const handleDateChange = (updatedDate) => {
    setDateRange(updatedDate);
  };

  const handleRefresh = () => {
    if(selectedProject || (startDate && endDate))
    setState((prev) => ({
      ...prev,
      selectedProject: "",
      startDate: "",
      endDate: "",
    }));
    setDateRange([null, null]);
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography color="#181818" fontSize={20} fontWeight={600}>
        Dashboard
      </Typography>
      <Box height={36} display="flex" justifyContent="space-between" gap={1}>
        <Select
          displayEmpty
          defaultValue=""
          value={selectedprojectId}
          onChange={(e) => setSelectedProjectId(e.target.value)}
          sx={{
            fontSize: 14,
            fontWeight: 400,
            borderColor: "#D1D9EF",
            borderRadius: 1,
            maxWidth: 160,
            color: "#9E9FA1",
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: 2,
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                overflow: "auto",
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            Project Name
          </MenuItem>
          {projects &&
            projects?.map((project) => (
              <MenuItem key={project?._id} value={project?._id}>
                {project?.projectName}
              </MenuItem>
            ))}
        </Select>
        <Box
          sx={{
            position: "relative",
            width: "auto",
            border: "1px solid #CCD2DE",
            borderRadius: 2,
            padding: 0,
            "&:hover": {
              borderColor: "#666",
            },
          }}
        >
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selectsRange
            startDate={startingDate}
            endDate={endingDate}
            onChange={handleDateChange}
            placeholderText="From date - To date"
            className="custom-date-input"
          />
          <CalendarIcon className="calendar-icon" />
        </Box>
        <Button
          variant="contained"
          sx={{
            fontSize: 13,
            fontWeight: 500,
            borderRadius: 2,
            textTransform: "capitalize",
            boxShadow: "none",
            backgroundColor: "#FF5E00BF",
            color: "white",
            "&:hover": {
              backgroundColor: "#FF7E33BF",
              boxShadow: "none",
            },
          }}
          onClick={() => applyFilters()}
        >
          Apply
        </Button>
        <Button
          variant="outlined"
          sx={{
            fontSize: 13,
            fontWeight: 500,
            borderRadius: 2,
            textTransform: "capitalize",
            boxShadow: "none",
            borderColor: "#D1D9EF",
            color: "#5F6A8A",
            "&:hover": {
              borderColor: "#666",
            },
          }}
          onClick={() => resetFilters()}
        >
          Reset
        </Button>
        <IconButton
          onClick={() => handleRefresh()}
          disabled={!(selectedProject || (startDate && endDate))}
          sx={{
            px: 1.25,
            fontSize: 13,
            fontWeight: 500,
            textTransform: "capitalize",
            color: "#D1D9EF",
            borderRadius: 2,
            border: 1,
            "&:hover": {
              borderColor: "#666",
            },
          }}
        >
          <RefreshIcon height={14} width={14} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Heading;
