import React, { useEffect, useState } from "react";
import {
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../../assets/Images/SearchIcon.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/Images/SettingsIcon.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/Images/SupportIcon.svg";
import VodexLogoCollapsed from "../../../../assets/Images/SingleLogo.png";
import VodexLogoExpanded from "../../../../assets/Images/VodexLogo.png";
import EmailMenu from "./EmailMenu";
import {
  getUerAccountsDetailsAction,
  saveUserDetailsAction,
} from "../../Actions/CommonActions";

const drawerWidth = 265;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    borderRight: "none",
  },
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const secondaryMenuItems = [
  {
    id: "settings",
    text: "Settings",
    icon: <SettingsIcon />,
    to: "settings",
  },
  { id: "support", text: "Support", icon: <SupportIcon />, to: "support" },
];

const Sidebar = ({
  styles,
  open,
  handleDrawer,
  setShowSettings,
  activeItemId,
  menuItems,
  setActiveItemId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: { userDetails },
  } = reducer;
  const [formData, setFormData] = useState(
    userDetails || {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      pincode: "",
    }
  );

  useEffect(() => {
    if (userDetails) {
      setFormData(userDetails);
    }
  }, [userDetails]);

  const updateUserDetails = async (updatedDetails) => {
    await dispatch(saveUserDetailsAction(updatedDetails));
    await dispatch(getUerAccountsDetailsAction());
  };

  const renderItems = (listItems) => {
    return (
      <List>
        {listItems.map((item, index) => (
          <ListItem
            sx={{ marginBottom: "5px" }}
            onClick={() => {
              if (item.to === "settings") {
                history.push("/account/access");
                setShowSettings(true);
              } else {
                history.push(item.to);
                setShowSettings(false);
              }
            }}
            key={item.text}
            disablePadding
          >
            <ListItemButton
              onClick={() => setActiveItemId(item.id)}
              sx={{
                ...styles.listItemConatiner,
                backgroundColor:
                  activeItemId === item.id ? "#FF6A00" : "transparent",
                px: `${open ? "10px" : "0"}`,
                justifyContent: `${open ? "space-between" : "center"}`,
                "&:hover": {
                  backgroundColor:
                    activeItemId === item.id ? "#FF6A00" : "#333",
                },
                transition: "background-color 0.3s",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: open ? "flex-start" : "center",
                }}
              >
                <span
                  style={{
                    color: activeItemId === item.id ? "#fff" : "#A0A0A0",
                  }}
                >
                  {item.icon}
                </span>
                {open && (
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: activeItemId === item.id ? "#fff" : "#A0A0A0",
                    }}
                  >
                    {item.text}
                  </Typography>
                )}
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Drawer
      sx={{
        ...styles.bg,
        "& .MuiDrawer-paper": {
          backgroundColor: styles.bg.backgroundColor,
        },
      }}
      variant="permanent"
      open={open}
    >
      <DrawerHeader
        sx={{
          ...styles.bg,
          display: "flex",
          justifyContent: "flex-start",
          paddingLeft: "24px",
        }}
      >
        <Box>
          {" "}
          <img
            src={open ? VodexLogoExpanded : VodexLogoCollapsed}
            alt="vodex"
          />
        </Box>
      </DrawerHeader>

      <Box sx={{ ...styles.bg }}>
        <Box mt={3} mx={2} mb={4}>
          <Box>
            {open ? (
              <TextField
                name="search"
                variant="outlined"
                fullWidth
                placeholder="Search"
                sx={styles.textInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Box
                component={"span"}
                onClick={handleDrawer}
                display={"flex"}
                justifyContent={"center"}
                sx={{ cursor: "pointer" }}
              >
                <SearchIcon />
              </Box>
            )}
          </Box>
        </Box>
        {open && <hr style={{ borderTop: "1px solid #242931" }} />}
        <Box mt={2} mx={2}>
          <Box>
            <Box>
              <Box>{renderItems(menuItems)}</Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "30px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                  width: "100%",
                  px: 2,
                }}
                mt={open ? 7 : 18}
              >
                {renderItems(secondaryMenuItems)}
                <EmailMenu
                  formData={formData}
                  setFormData={setFormData}
                  editModalOpen={editModalOpen}
                  setEditModalOpen={setEditModalOpen}
                  userDetails={userDetails}
                  updateUserDetails={updateUserDetails}
                  styles={styles}
                  open={open}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
