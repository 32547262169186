import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { LoadingDots, PAGE_SIZE_50 } from "../../../CommonComponents/Common";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCampaignDetailsAction,
  getCallHistoryAction,
  getCallHistoryCountAction,
  getCampaignDetailsAction,
} from "../Store/Campaign.action";
import moment, { max, min } from "moment";
import "./CampaignDetails.css";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Assets
import { ReactComponent as FilterIcon } from "../../../../../assets/Images/FilterIcon.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/Images/CalendarIcon.svg";
import { ReactComponent as SmallCrossIcon } from "../../../../../assets/Images/SmallCrossIcon.svg";
import { Box } from "@mui/material";

// Styling For Select
const customStyle = {
  control: (base: any, state: any) => ({
    ...base,
    borderRadius: "8px",
    fontSize: "13px",
    padding: "4px",
    color: "#55555",
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "#9E9FA1",
    fontSize: "14px",
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "#55555",
    fontSize: "13px",
  }),
  indicatorSeparator: () => ({
    display: "none",
    color: "#555555",
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: "8px",
    marginTop: "4px",
    zIndex: 9999,
    fontSize: "13px",
  }),
  option: (base: any, state: any) => ({
    ...base,
    fontSize: "13px",
    color: state.isSelected ? "#FFFFFF" : "#212529",
    padding: "10px 15px",
    cursor: "pointer",
  }),
};
const CustomPlaceholder = (props: any) => (
  <components.Placeholder {...props}>
    <span style={{ marginRight: "13px" }}>
      <FilterIcon />
    </span>
    {props.children}
  </components.Placeholder>
);

function NewFilterDrawerBody({ obj, all = false, main = false }) {
  const {
    campaignRuns,
    setMainState,
    trackEnabledRecording,
    mainState,
    campaignId,
  } = obj;
  const reducer: any = useSelector((state) => state);
  const dispatch = useDispatch();
  const {
    AccountReducer: { constantData },
    ProjectReducer: { projectList },
  } = reducer;

  const CALL_STATUS = constantData?.callStatus;
  const [state, setState] = useState<any>({
    outComeArr: [],
    loading: false,
    error: false,
    saveFilter: false,
    selectedSavedFilter: "",
    saveDate: false,
  });
  const currentDate = moment().format("YYYY-MM-DD");
  let filterData: any = localStorage.getItem("callHistoryFilterData");
  let filterParsed = JSON.parse(filterData);
  const [dateRange, setDateRange] = useState([
    new Date(filterParsed?.startDate || currentDate),
    new Date(filterParsed?.endDate || currentDate),
  ]);
  const [startDate, endDate] = dateRange;
  const {
    durationFilter,
    statusFilter,
    outComefilter,
    selectedProjectId,
    searchText,
    filterName,
  } = mainState;
  const { outComeArr, loading, error, saveFilter, saveDate } = state;
  const [showAll, setShowAll] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [showDate, setShowDate] = useState(true);

  let arraMapped: any = [
    {
      title: "Filter Calls with duration",
      innerMap: ["0-15 sec", "15-30 sec", "30+ sec", "Custom"],
      valueAssigned: "durationFilter",
      title1: "Duration",
    },
  ];
  if (outComeArr?.length > 0) {
    arraMapped = [
      {
        title: "Filter Calls with outcome",
        isObject: true,
        innerMap: outComeArr,
        valueAssigned: "outComefilter",
        title1: "outcome",
      },
      ...arraMapped,
    ];
  }
  const FILTERS = [
    "703",
    "704",
    "712",
    "710",
    "705",
    "716",
    "718",
    "706",
    "707",
    "708",
    "709",
    "711",
    "713",
  ];
  const INITIAL_COUNT = 7;
  const filtersToShow = showAll ? FILTERS : FILTERS.slice(0, INITIAL_COUNT);
  const savedStatusFilter =
    showAll && statusFilter.length > 7
      ? statusFilter
      : statusFilter.slice(0, INITIAL_COUNT);
  const displayedFilters = saveFilter ? savedStatusFilter : filtersToShow;

  const selectedProjectName = projectList?.find(
    (project) => project?._id === selectedProjectId
  )?.projectName;

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      outComeArr: trackEnabledRecording,
    }));
  }, [trackEnabledRecording]);

  useEffect(() => {
    const filtersLoc: any = localStorage.getItem("savedFilterData");
    const filters = JSON.parse(filtersLoc) || [];
    setSavedFilters(filters);
  }, []);

  useEffect(() => {
    const savedFiltersLoc: any = localStorage.getItem("savedFilters");
    const savedFilters = JSON.parse(savedFiltersLoc);
    if (savedFilters) {
      setMainState((prev) => ({
        ...prev,
        filterName: savedFilters?.filterName,
      }));
    }
  }, []);

  const onChange = (name, value) => {
    if (name === "outComefilter") {
      let arr = outComefilter;
      let find = arr.find((o) => o === value.tag);
      if (find) {
        arr = arr.filter((o) => {
          return o !== value.tag;
        });
      } else {
        arr.push(value.tag);
      }
      setMainState((prev) => ({
        ...prev,
        outComefilter: arr,
      }));
    } else {
      if (name === "statusFilter") {
        let arr = statusFilter;
        let find = arr.find((o) => o === value);
        if (find) {
          arr = arr.filter((o) => {
            return o !== value;
          });
        } else {
          arr.push(value);
        }
        setMainState((prev) => ({
          ...prev,
          statusFilter: arr,
        }));
      } else {
        setMainState((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };
  const clearFilter = async (val, prevPage = false) => {
    if (saveFilter) {
      if (prevPage) {
        localStorage.removeItem("savedFilters");
        localStorage.removeItem("callHistoryFilterData");
        localStorage.removeItem("selectedFilter");
        setMainState((prev) => ({
          ...prev,
          durationFilter: "",
          outComefilter: [],
          statusFilter: [],
          min: "",
          max: "",
          selectedProjectId: null,
          filterName: "",
        }));
        setState((prev) => ({
          saveFilter: false,
          saveDate:false
        }));
        setShowDate(true);
      }
      return;
    }
    if (val === "all") {
      localStorage.removeItem("savedFilters");
      localStorage.removeItem("callHistoryFilterData");
      localStorage.removeItem("selectedFilter");
      setMainState((prev) => ({
        ...prev,
        durationFilter: "",
        outComefilter: [],
        statusFilter: [],
        min: "",
        max: "",
        selectedProjectId: "",
        filterName: "",
      }));
      let obj = {
        pageIndex: 1,
        pageSize: PAGE_SIZE_50,
        live: false,
        duration: "",
        callStatus: [],
        outcome: [],
        projectId: "",
      };
      if (main) {
        obj["from"] = moment(startDate).format("YYYY-MM-DD");
        obj["to"] = moment(endDate).format("YYYY-MM-DD");
        await dispatch(getCallHistoryAction(obj));
        await dispatch(getCallHistoryCountAction(obj));
      } else {
        if (all) {
          await dispatch(
            getAllCampaignDetailsAction(campaignId, {
              ...obj,
              isDocsOnly: true,
            })
          );
          await dispatch(
            getAllCampaignDetailsAction(campaignId, {
              ...obj,
              isDocsOnly: false,
            })
          );
        } else {
          await dispatch(
            getCampaignDetailsAction(selectedProjectId, campaignRuns, obj)
          );
        }
      }
      setMainState((prev) => ({
        ...prev,
        filterDrawer: false,
        pageIndex: 1,
      }));
      return;
    }
    let kl = val === "outComefilter" || val === "statusFilter" ? [] : "";
    let obj2 = {
      durationFilter: "duration",
      statusFilter: "callStatus",
      outComefilter: "outcome",
    };
    let obj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${mainState?.min}-${mainState?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      projectId: selectedProjectId,
    };
    delete obj[obj2[val]];
    if (main) {
      obj["from"] = moment(startDate).format("YYYY-MM-DD");
      obj["to"] = moment(endDate).format("YYYY-MM-DD");
      await dispatch(getCallHistoryAction(obj));
      await dispatch(getCallHistoryCountAction(obj));
    } else {
      if (all) {
        await dispatch(
          getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: true })
        );
        await dispatch(
          getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: false })
        );
      } else {
        await dispatch(
          getCampaignDetailsAction(selectedProjectId, campaignRuns, obj)
        );
      }
    }
    setMainState((prev) => ({
      ...prev,
      [val]: kl,
      pageIndex: 1,
    }));
  };

  const applyFilter = async (scenario = "apply") => {
    if (scenario === "apply") {
      const appliedFilters = {
        selectedProjectId,
        outComefilter,
        durationFilter,
        statusFilter,
        customFilter:
          durationFilter === "Custom"
            ? `${mainState?.min}-${mainState?.max}`
            : "",
      };
      localStorage.setItem("savedFilters", JSON.stringify(appliedFilters));
    }
    if (scenario === "locApply") {
      const newFilter = {
        filterName,
        startDate,
        endDate,
        durationFilter,
        statusFilter,
        outComefilter,
        selectedProjectId,
        customFilter:
          durationFilter === "Custom"
            ? `${mainState?.min}-${mainState?.max}`
            : "",
      };
      const existingFiltersLoc: any = localStorage.getItem("savedFilterData");
      const existingFilters = JSON.parse(existingFiltersLoc) || [];
      const updatedFilters = [...existingFilters, newFilter];
      localStorage.setItem("savedFilterData", JSON.stringify(updatedFilters));
      localStorage.setItem("savedFilters", JSON.stringify(newFilter));
    }

    localStorage.setItem(
      "callHistoryFilterData",
      JSON.stringify({
        startDate,
        endDate,
        searchText,
      })
    );

    if (
      durationFilter === "Custom" &&
      mainState?.min?.trim() === "" &&
      mainState?.max?.trim() === ""
    ) {
      setState((prev) => ({
        ...prev,
        error: true,
        saveFilter: false,
      }));
      return false;
    }
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    let obj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${mainState?.min}-${mainState?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      projectId: selectedProjectId,
    };
    if (main) {
      obj["from"] = moment(startDate).format("YYYY-MM-DD");
      obj["to"] = moment(endDate).format("YYYY-MM-DD");
      await dispatch(getCallHistoryAction(obj));
      await dispatch(getCallHistoryCountAction(obj));
    } else {
      if (all) {
        await dispatch(
          getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: true })
        );
        await dispatch(
          getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: false })
        );
      } else {
        await dispatch(
          getCampaignDetailsAction(selectedProjectId, campaignRuns, obj)
        );
      }
    }
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
    setMainState((prev) => ({
      ...prev,
      filterDrawer: false,
      pageIndex: 1,
    }));
  };

  const getDisabled = () => {
    if (
      durationFilter ||
      statusFilter?.length > 0 ||
      outComefilter.length > 0 ||
      startDate ||
      endDate ||
      selectedProjectId
    ) {
      return false;
    } else return true;
  };

  const onChange1 = (e) => {
    e.persist();
    if (e.target.value && Number(e.target.value) <= 0) {
      return;
    } else {
      setMainState((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  let dur =
    durationFilter === "Custom"
      ? `${mainState?.min}-${mainState?.max}`
      : durationFilter?.split(" ")[0];

  const handleFilterSelect = (e) => {
    const obj = {
      durationFilter: e.durationFilter,
      statusFilter: e.statusFilter,
      outComefilter: e.outComefilter,
      selectedProjectId: e.selectedProjectId,
      filterName: e.filterName,
      customFilter: e.customFilter,
      min: e.customFilter.split("-")[0] || "",
      max: e.customFilter.split("-")[1] || "",
    };
    setMainState((prev) => ({
      ...prev,
      ...obj,
    }));
    localStorage.setItem(
      "savedFilters",
      JSON.stringify({ ...obj, filterName: e.filterName })
    );
    let obj1 = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        e.durationFilter === "Custom" ? e.customFilter : e.durationFilter,
      callStatus: e.statusFilter,
      outcome: e.outComefilter,
      projectId: e.selectedProjectId,
      from: moment(e.startDate).format("YYYY-MM-DD"),
      to: moment(e.endDate).format("YYYY-MM-DD"),
    };
    dispatch(getCallHistoryAction(obj1));
    dispatch(getCallHistoryCountAction(obj1));
    setMainState((prev) => ({
      ...prev,
      filterDrawer: false,
      pageIndex: 1,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
      }}
    >
      <Box sx={{ minWidth: "353px", padding: "17px 23px 0 23px" }}>
        {" "}
        <Box>
          <Box className="flex-column" sx={{ gap: "10px" }}>
            <span style={{ fontSize: "14px", fontWeight: 600 }}>
              Apply Filters
            </span>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#555555",
              }}
            >
              Filter table data and save filters.
            </span>
            {saveFilter ? (
              <Box
                sx={{
                  border: "1px solid #CCD2DE",
                  borderRadius: "8px",
                  padding: "0 16px",
                  margin: "10px 0 20px 0",
                }}
                className="flex-align-center"
              >
                <FilterIcon />
                <input
                  onChange={(e) => {
                    e.persist();
                    setMainState((prev) => ({
                      ...prev,
                      [e.target?.name]: e.target?.value,
                    }));
                  }}
                  name="filterName"
                  value={filterName}
                  className="custm-ip ml-3"
                  style={{ height: "40px" }}
                  placeholder="Enter a filter name"
                />
              </Box>
            ) : (
              <Select
                maxMenuHeight={180}
                className="basic-single mt-2"
                classNamePrefix="select"
                isSearchable={false}
                options={savedFilters}
                value={savedFilters.find(
                  (filter: any) =>
                    filter?.filterName === mainState.filterName || null
                )}
                getOptionValue={(e: any) => e.filterName}
                getOptionLabel={(e: any) => e.filterName}
                placeholder="Select saved filter"
                styles={customStyle}
                onChange={(e) => {
                  handleFilterSelect(e);
                }}
                components={{
                  Placeholder: CustomPlaceholder,
                }}
              />
            )}
          </Box>

          {saveFilter && (
            <span
              className="mt-3"
              style={{ fontSize: "12px", fontWeight: 600, color: "#181818" }}
            >
              Selected Filters
            </span>
          )}

          {showDate && (
            <Box className="mt-3 flex-column" sx={{ gap: "13px" }}>
              {saveDate === true ? (
                <Box>
                  <span className="font-12-grey">Date</span>
                  <Box className="mt-2">
                    <Box
                      className="flex-around"
                      sx={{
                        width: "211px",
                        padding: "0 5px",
                        height: "24px",
                        backgroundColor: "#F1F4FA",
                        borderRadius: "8px",
                      }}
                    >
                      <CalendarIcon style={{ height: "11px", width: "11px" }} />
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#555555",
                        }}
                      >
                        {moment(startDate).format("DD-MM-YYYY")} -
                        {moment(endDate).format("DD-MM-YYYY")}
                      </span>
                      <SmallCrossIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDateRange([
                            new Date(currentDate),
                            new Date(currentDate),
                          ]);
                          setState((prev) => ({
                            ...prev,
                            saveDate: false,
                          }));
                          setShowDate(false);
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : null}
              {!saveDate && (
                <Box>
                  <span className="font-12-grey">Date</span>
                  <Box
                    className="new-date-picker mt-2"
                    sx={{ position: "relative", padding:"6px" }}
                  >
                    {/* DatePicker Component */}
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      title={""}
                      maxDate={addDays(new Date(), 0)}
                      close={false}
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      placeholderText="From date - To date"
                      className="custom-date-input"
                    />
                    {/* Calendar Icon */}
                    <span className="calendar-icon datepicker-icon">
                      <CalendarIcon />
                    </span>
                  </Box>
                </Box>
              )}
            </Box>
          )}

          <Box className="mt-3">
            {saveFilter && selectedProjectId ? (
              <Box>
                <span className="font-12-grey">Project</span>
                <Box
                  className="flex-evenly mt-2"
                  sx={{
                    width: "82px",
                    padding: "0px 5px",
                    height: "24px",
                    backgroundColor: "#F1F4FA",
                    borderRadius: "8px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#555555",
                    }}
                  >
                    {selectedProjectName}
                  </span>
                  <SmallCrossIcon
                    onClick={() => {
                      setMainState((prev) => ({
                        ...prev,
                        selectedProjectId: null,
                        saveFilter: false,
                      }));
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            ) : saveFilter ? null : (
              <Box>
                <span className="font-12-grey">Project</span>
                <Select
                  styles={customStyle}
                  maxMenuHeight={180}
                  className="basic-single mt-3"
                  classNamePrefix="select"
                  isDisabled={false}
                  isSearchable={true}
                  options={projectList}
                  value={
                    selectedProjectId
                      ? projectList?.find(
                          (project) => project?._id === selectedProjectId
                        )
                      : null
                  }
                  getOptionLabel={(e: any) => e.projectName}
                  getOptionValue={(e: any) => e._id}
                  onChange={(e) =>
                    setMainState((prev) => ({
                      ...prev,
                      selectedProjectId: e?._id,
                    }))
                  }
                  placeholder="Select Project"
                />
              </Box>
            )}
          </Box>

          {/* Duration Filter */}
          <Box>
            {saveFilter && durationFilter ? (
              <>
                <span className="font-12-grey">Duration</span>
                <Box className="mt-3">
                  <Box
                    className="flex-around"
                    sx={{
                      width: "105px",
                      height: "24px",
                      backgroundColor: "#F1F4FA",
                      borderRadius: "8px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#555555",
                      }}
                    >
                      {dur}
                    </span>
                    <SmallCrossIcon
                      onClick={() => {
                        setMainState((prev) => ({
                          ...prev,
                          durationFilter: "",
                          saveFilter: false,
                        }));
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                </Box>
              </>
            ) : saveFilter ? null : (
              <Box className="mt-3">
                <span className="font-12-grey">Duration</span>
                <Box
                  className="mt-3"
                  sx={{
                    height: "40px",
                    background: "#F1F4FA",
                    borderRadius: "100px",
                    padding: "0 5px",
                  }}
                >
                  {arraMapped.map((arr) => (
                    <Box sx={{ paddingTop: "4px" }}>
                      <Box className="flex-evenly">
                        {arr.innerMap?.map((innerArr, i) => {
                          const dur = arr?.title === "Filter Calls with status";
                          const isSelected = arr?.isObject
                            ? outComefilter.find((o) => o === innerArr?.tag)
                            : mainState[arr?.valueAssigned] === innerArr ||
                              mainState[arr?.valueAssigned].includes(innerArr);

                          return (
                            <Box>
                              <Box
                                onClick={() =>
                                  onChange(arr?.valueAssigned, innerArr)
                                }
                                sx={{
                                  width: "70px",
                                  height: "32px",
                                  background: isSelected
                                    ? "#FFFFFF"
                                    : "#F1F4FA",
                                  borderRadius: "100px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span className="font-12-lightGrey">
                                  {!arr?.isObject &&
                                    (dur ? CALL_STATUS[innerArr] : innerArr)}
                                  {arr?.isObject && innerArr?.name}
                                </span>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box>
                  {durationFilter === "Custom" && (
                    <Box>
                      <span className="font-12-lightGrey mt-2">
                        Custom Duration
                      </span>
                      <Box className="flex-between">
                        <input
                          style={{ width: "150px" }}
                          placeholder="from (in sec.)"
                          name="min"
                          min={1}
                          type="number"
                          className="form-control custm-ip mr-1"
                          value={mainState?.min}
                          onChange={onChange1}
                        />
                        <input
                          style={{ width: "150px" }}
                          placeholder="to (in sec.)"
                          name="max"
                          min={1}
                          type="number"
                          className="form-control custm-ip"
                          value={mainState?.max}
                          onChange={onChange1}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          {/* Status Filter */}
          <Box className="mt-3">
            {displayedFilters?.length > 0 &&
              (saveFilter ? (
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#181818",
                  }}
                >
                  Selected Filters
                </span>
              ) : (
                <span className="font-12-grey">Call Status</span>
              ))}
            <ul>
              {displayedFilters.map((innerArr) => {
                return (
                  <li key={`staat${innerArr}`}>
                    <Row className="mt-3">
                      <Box>
                        <Box className="container">
                          <Box className="flex-align-center">
                            <input
                              type="checkbox"
                              id={"cbx2"}
                              checked={mainState["statusFilter"].includes(
                                innerArr
                              )}
                              onChange={() =>
                                onChange("statusFilter", innerArr)
                              }
                            />
                            <label
                              htmlFor="cbx2"
                              className="font-12-grey ml-3 check-label"
                            >
                              {CALL_STATUS[innerArr]}
                            </label>
                          </Box>
                        </Box>
                      </Box>
                    </Row>
                  </li>
                );
              })}
            </ul>

            {(!saveFilter || (statusFilter.length > 5 && saveFilter)) && (
              <span
                style={{ cursor: "pointer" }}
                className="font-12-lightGrey"
                onClick={() => setShowAll((prev) => !prev)}
              >
                {showAll ? "see less" : "see more"}
              </span>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ maxHeight: "70px" }}>
        <hr style={{ marginBottom: "3px", marginTop: "18px" }} />
        <Box
          className="flex-between"
          sx={{
            padding: "5px 23px",
          }}
        >
          <Box>
            {saveFilter || (
              <span
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    saveFilter: true,
                    saveDate: true,
                  }))
                }
                className="font-13-lightgrey"
                style={{
                  cursor: "pointer",
                }}
              >
                Save Filter
              </span>
            )}
          </Box>
          <Box className="flex-align-center">
            <button
              style={{ width: "70px", height: "35px" }}
              className="project-button mr-2"
              onClick={() => clearFilter("all", true)}
            >
              Cancel
            </button>
            {saveFilter || (
              <button
                style={{ width: "92px", height: "35px" }}
                className="cmn-button"
                disabled={getDisabled() || loading}
                onClick={() => applyFilter("apply")}
              >
                {loading ? <LoadingDots /> : "Apply filter"}
              </button>
            )}
            {saveFilter && (
              <button
                style={{ width: "102px", height: "40px" }}
                className="cmn-button"
                disabled={getDisabled() || loading}
                onClick={() => applyFilter("locApply")}
              >
                {loading ? <LoadingDots /> : "Save & Apply"}
              </button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default NewFilterDrawerBody;
