import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { leftMenuStyle } from "./styles";

import { ReactComponent as CallHistoryIcon } from "../../../../assets/Images/CallHistoryIcon.svg";
import { ReactComponent as DasboardIcon } from "../../../../assets/Images/Dashboard.svg";
import { ReactComponent as LeftPointer } from "../../../../assets/Images/LeftPointer.svg";
import { ReactComponent as ProjectIcon } from "../../../../assets/Images/ProjectIcon.svg";
import { ReactComponent as QuickStartIcon } from "../../../../assets/Images/Quick start.svg";
import { ReactComponent as RightPointer } from "../../../../assets/Images/RightPointer.svg";

import NewRoutes from "./NewRoutes";
import ProjectSidebar from "./ProjectSidebar";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const menuItems = [
  {
    id: "quick_start",
    text: "Quick start",
    icon: <QuickStartIcon />,
    to: "/onboarding?from=dashboard",
  },
  {
    id: "dashboard",
    text: "Dashboard",
    icon: <DasboardIcon />,
    to: "/account/dashboard",
  },
  {
    id: "projects",
    text: "Projects",
    icon: <ProjectIcon />,
    to: "/account/projects",
  },
  {
    id: "call_history",
    text: "Call History",
    icon: <CallHistoryIcon />,
    to: "/account/call-history",
  },
];

export default function MainLayout() {
  const [open, setOpen] = useState(false);
  const [activeItemId, setActiveItemId] = useState("dashboard");
  const [showSettings, setShowSettings] = useState(false);

  const theme = useTheme();
  const styles = leftMenuStyle(theme, open);
  const location = useLocation();
  const isProjectDetailPage =
    location.pathname.startsWith("/account/project/") &&
    location.pathname !== "/account/projects";

  useEffect(() => {
    setOpen(false);
  }, [isProjectDetailPage]);

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes("/account/project/")) {
      setActiveItemId("projects");
    } else {
      const activeItem = menuItems.find((item) => currentPath === item.to);
      if (activeItem) {
        setActiveItemId(activeItem.id);
      }
    }
  }, [location?.pathname]);

  const handleDrawer = () => setOpen((prev) => !prev);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: "100vh",
        backgroundColor: "#181818",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          height: 64,
          zIndex: 100,
          position: "relative",
        }}
      >
        <Topbar
          styles={styles}
          open={open}
          showSettings={showSettings}
          theme={theme}
        />
      </Box>
      {!isProjectDetailPage && (
        <Box sx={open ? styles.leftPointer : styles.rightPointer}>
          {open ? (
            <LeftPointer onClick={handleDrawer} />
          ) : (
            <RightPointer onClick={handleDrawer} />
          )}
        </Box>
      )}

      <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
        <Sidebar
          styles={styles}
          menuItems={menuItems}
          open={open}
          handleDrawer={handleDrawer}
          setShowSettings={setShowSettings}
          activeItemId={activeItemId}
          setActiveItemId={setActiveItemId}
        />
        {isProjectDetailPage && <ProjectSidebar />}
        <Box
          sx={{
            flexGrow: 1,
            height: "calc(100vh - 64px)",
            overflow: "hidden",
            marginLeft: isProjectDetailPage ? "200px" : "0",
            transition: "margin-left 0.3s ease",
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              borderRadius: "12px",
              height: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                px: 3,
                pt: 2,
                flex: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar": { display: "none" },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              <NewRoutes />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
