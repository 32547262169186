import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";

const styles = {
  label: {
    color: "#1A1D23",
    fontSize: 14,
    fontWeight: "medium",
    mb: 0.5,
  },
  textInput: {
    "& .MuiOutlinedInput-root": {
      fontSize: 13,
      fontWeight: 400,
      borderRadius: 2,
      borderColor: "#CCD2DE",
      borderWidth: "1px",
      transition: "border-color 0.3s ease",
    },
    "& .MuiOutlinedInput-input": {
      px: 1.5,
      py: 1,
      "&::placeholder": {
        color: "#555555",
        fontSize: 13,
        fontWeight: 400,
      },
    },
  },
};

const UpdateProfile = ({
  open,
  formData,
  setFormData,
  handleModalClose,
  updateUserDetails,
  focusableTabIndex,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    updateUserDetails(formData);
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      handleModalClose();
    }, 300);
  };

  return ReactDOM.createPortal(
    <Box
      sx={{
        position: "fixed",
        bottom: "20px",
        left: open ? "280px" : "90px",
        width: "500px",
        bgcolor: "white",
        boxShadow: 24,
        borderRadius: "8px",
        zIndex: 100,
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.3s ease, transform 0.3s ease",
        maxHeight: "calc(80vh - 0)",
      }}
    >
      <Box
        sx={{
          pt: 2,
          pb: 1.5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "150px",
          "@media (max-height: 600px)": {
            pt: 1.5,
            pb: 1,
          },
        }}
      >
        <Avatar
          sx={{
            backgroundColor: "#D04D00",
            width: 60,
            height: 60,
            fontSize: "2rem",
            mb: 1,
            transition: "transform 0.3s ease",
            "&:hover": { transform: "scale(1.1)" },
          }}
        >
          {formData.email?.charAt(0)?.toUpperCase()}
        </Avatar>
        <Typography color="#181818" fontSize={18} fontWeight={600}>
          {formData.firstName} {formData.lastName}
        </Typography>
        <Typography color="#181818" fontSize={16} fontWeight={500} gutterBottom>
          {formData.email}
        </Typography>
      </Box>
      <Box
        px={2.5}
        pt={1.5}
        borderTop="1px solid #D1D9EF"
        borderBottom="1px solid #D1D9EF"
        sx={{
          maxHeight: "calc(70vh - 0px)",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "#CCD2DE transparent",
          "@media (max-height: 600px)": {
            maxHeight: "calc(60vh + 10px)",
          },
          "@media (max-height: 550px)": {
            maxHeight: "calc(50vh - 0px)",
          },
          "@media (max-height: 450px)": {
            maxHeight: "calc(40vh - 0px)",
          },
        }}
      >
        <Box display="flex" gap={2.5}>
          <Box width={1 / 2} mb={1}>
            <Typography {...styles.label}>First Name</Typography>
            <TextField
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder="Enter your first name"
              sx={styles.textInput}
            />
          </Box>
          <Box width={1 / 2} mb={1}>
            <Typography {...styles.label}>Last Name</Typography>
            <TextField
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder="Enter your last name"
              sx={styles.textInput}
            />
          </Box>
        </Box>
        <Box mb={1}>
          <Typography {...styles.label}>Email</Typography>
          <TextField
            name="email"
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="mailid@gmail.com"
            sx={styles.textInput}
          />
        </Box>
        <Box mb={1}>
          <Typography {...styles.label}>Phone number</Typography>
          <TextField
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your Phone Number"
            sx={styles.textInput}
          />
        </Box>
        <Box mb={1}>
          <Typography {...styles.label}>Address</Typography>
          <TextField
            name="address"
            value={formData.address}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your address here"
            sx={styles.textInput}
          />
        </Box>
        <Box display="flex" gap={2.5}>
          <Box width={1 / 2} mb={1}>
            <Typography {...styles.label}>City</Typography>
            <TextField
              name="city"
              value={formData.city}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder="Enter your city"
              sx={styles.textInput}
            />
          </Box>
          <Box width={1 / 2} mb={1}>
            <Typography {...styles.label}>Pincode</Typography>
            <TextField
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder="Enter your Pincode"
              sx={styles.textInput}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: 2.5,
          pb: 1.5,
          display: "flex",
          justifyContent: "flex-end",
          mt: 1.5,
          gap: 2,
          "@media (max-height: 600px)": {
            pb: 1,
          },
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            fontSize: 13,
            fontWeight: 500,
            backgroundColor: "white",
            color: "#555555",
            borderRadius: "8px",
            textTransform: "capitalize",
            boxShadow: "none",
            height: "40px",
            borderColor: "#CCD2DE",
            "&:hover": {
              backgroundColor: "white",
              borderColor: "#CCD2DE",
            },
          }}
        >
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            fontSize: 13,
            fontWeight: 500,
            backgroundColor: "#FF5E00BF",
            color: "white",
            borderRadius: "8px",
            textTransform: "capitalize",
            boxShadow: "none",
            height: "40px",
            "&:hover": {
              backgroundColor: "#FF5E00BF",
              boxShadow: "none",
            },
          }}
        >
          Update profile
        </Button>
      </Box>
    </Box>,
    document.body
  );
};

export default UpdateProfile;
