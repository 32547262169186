import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingDots, LoadingSlides } from "../CommonComponents/Common";
import {
  getDashboardChartAction,
  getDashboardDataAction,
} from "../Store/Account/Account.action";
import BarChart from "./BarChart";
import CampaignList from "./CampaignList";
import ConsumedBalance from "./ConsumedBalance";
import DoughnutChart from "./DoughnutChart";
import Heading from "./Heading";
import SummaryCard from "./SummaryCard";
import "./dashboard.css";

const DashboardHome = () => {
  const dispatch = useDispatch();
  const reducer: any = useSelector((reducer) => reducer);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    selectedProject: "",
    startDate: "",
    endDate: "",
  }) as any;

  const {
    AccountReducer: { dashboardData, chartData },
    ProjectReducer: { projectList },
  } = reducer;
  const { totalCost, balance, remainingMinutes, onHoldBalance } = dashboardData;

  useEffect(() => {
    const params = {
      projectId: state.selectedProject,
      from: state.startDate,
      to: state.endDate,
    };
    setLoading(true);
    const fetchData = async () => {
      await dispatch(getDashboardDataAction(params));
      await dispatch(getDashboardChartAction(params));
      setLoading(false);
    };
    fetchData();
  }, [state]);

  if (loading) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 10,
        }}
      >
        <LoadingSlides bgColor="#FF5E00" />
      </Box>
    );
  }

  return (
    <Box p={1}>
      <Heading projectList={projectList} state={state} setState={setState} />
      <Grid mt={0.25} mb={2} container spacing={2}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ConsumedBalance
                title="Consumed Balance"
                value1={!totalCost ? <LoadingDots /> : `$ ${totalCost} `}
                value2={!balance ? <LoadingDots /> : `$ ${balance} `}
                barValue={
                  totalCost && balance
                    ? Math.min((totalCost / balance) * 100, 100)
                    : 0
                }
                color="linear-gradient(268.11deg, #FFDA8F 9.11%, #EEB644 105.62%)"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SummaryCard
                title="Remaining mins"
                circleColor="linear-gradient(60.26deg, #79EE81 15.53%, #26C231 89.39%)"
                value={remainingMinutes}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SummaryCard
                title="On Hold Balance"
                circleColor="linear-gradient(180deg, #8FA8FF 0%, #4265E2 100%)"
                value={!onHoldBalance ? <LoadingDots /> : `$ ${onHoldBalance} `}
              />
            </Grid>
            <Grid item xs={12}>
              <BarChart chartData={chartData} state={state} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <DoughnutChart chartData={dashboardData} />
        </Grid>
      </Grid>
      <CampaignList />
    </Box>
  );
};

export default DashboardHome;
