import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

const ConsumedBalance = ({ title, value1, value2, barValue, color }) => {
  return (
    <Box
      sx={{
        minHeight: 100,
        backgroundColor: "white",
        padding: 2,
        borderRadius: 2,
        border: "1px solid #D1D9EF",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 1,
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={{
              width: 12,
              height: 12,
              background: color,
              borderRadius: "50%",
              marginRight: 1,
            }}
          />
          <Typography color="#555555" fontSize={14} fontWeight={500}>
            {title}
          </Typography>
        </Box>
        {value1 && value2 && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography mr={0.5} color="#181818" fontSize={16} fontWeight={700}>
              {value1}
            </Typography>
            /
            <Typography ml={0.5} color="#555555" fontSize={14} fontWeight={500}>
              {value2}
            </Typography>
          </Box>
        )}
      </Box>

      <LinearProgress
        variant="determinate"
        value={barValue}
        sx={{
          height: 10,
          borderRadius: 5,
          my: 2,
          backgroundColor: "#e0e0e0",
          "& .MuiLinearProgress-bar": {
            background: color,
          },
        }}
      />
    </Box>
  );
};

export default ConsumedBalance;
