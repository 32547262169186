import React from "react";
import { IconButton } from "@mui/material";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactComponent as Info } from "../../../assets/icons/info.svg";
import { shortenString } from "../../../Constant/helper";

const CallHistoryPopover = ({ callData }) => {
  const convertTime = (time) => {
    const date = new Date(time);
    return date.toLocaleString();
  };

  return (
    <OverlayTrigger
      trigger={["hover","focus"]}
      placement="left"
      overlay={
        <Popover id="popover-basic" style={{ zIndex: 1200 }}>
          <Popover.Title as="h3">
            <span className="mt-3"> Call Details </span>
          </Popover.Title>
          <Popover.Content style={{ maxHeight: "200px", overflow: "auto" }}>
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
              }}
            >
              <li>
                <strong> Project Name : </strong>{" "}
                {shortenString(callData?.projectName, 15)}
              </li>
              <li>
                <strong> Campaign Name :</strong>{" "}
                {shortenString(callData?.campaignName, 15)}
              </li>
              <li>
                <strong> Campaign Run Time : </strong>
                {convertTime(callData?.createdAt)}
              </li>
            </ul>
          </Popover.Content>
        </Popover>
      }
      rootClose={true}
      rootCloseEvent="click"
      onExit={() => {}}
    >
      <IconButton title="Info">
        <Info />
      </IconButton>
    </OverlayTrigger>
  );
};

export default CallHistoryPopover;
