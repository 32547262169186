export const leftMenuStyle = (theme, open) => ({
  bg: {
    backgroundColor: "#181818",
  },
  leftPointer: {
    position: "fixed",
    top: "52px",
    left: "256px",
    zIndex: "9999",
    cursor: "pointer",
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  rightPointer: {
    position: "fixed",
    top: "53px",
    left: "60px",
    zIndex: "9999",
    cursor: "pointer",
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  textInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
      backgroundColor: "#111111",
      borderColor: "#2F353E",
      borderWidth: "1px",
      color: "#FFFFFF",
      fontSize: "14px",
      fontWeight: "400",
      height: "40px",
      "& fieldset": {
        borderColor: "#2F353E",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#2F353E",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2F353E",
        borderWidth: "1px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: 1.5,
      "&::placeholder": {
        color: "white",
        fontSize: 13,
        fontWeight: 400,
      },
    },
  },
  listItemConatiner: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    minHeight: "40px",
    width: "100%",
    borderRadius: "8px",
    cursor: "pointer",
  },
  mailStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "orange",
    height: "23px",
    width: "23px",
    borderRadius: "100%",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "12px",
  },
  settingsBox: {
    display: "flex",
    gap: 1,
    position: "fixed",
    top: "24px",
    width: "500px",
    left: `${open ? "264px" : "72px"}`,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  settingsNav: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "300px",
    height: "40px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    cursor: "pointer",
  },
});

const sharedStyles = { color: "#9E9FA1", fontSize: "14px", fontWeight: "400" };

export const customStyles = {
  control: (base, state) => ({
    ...base,
    minWidth: "232px",
    backgroundColor: "#111111",
    color: "#9E9FA1",
    fontSize: "14px",
    fontWeight: "400",
    border: "1px solid #2F353E",
    borderRadius: "8px",
    boxShadow: state.isFocused ? "0 0 0 1px #2F353E" : "none",
    "&:hover": {
      border: "1px solid #2F353E",
    },
  }),
  placeholder: (base) => ({
    ...base,
    ...sharedStyles,
  }),
  singleValue: (base) => ({
    ...base,
    ...sharedStyles,
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#111111",
    borderRadius: "8px",
    border: "1px solid #2F353E",
    color: "#9E9FA1",
    boxShadow: "none",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    borderRadius: "8px",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "#2F353E" : "#111111",
    color: state.isSelected ? "#FFFFFF" : "#9E9FA1",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#2F353E",
      color: "#FFFFFF",
    },
    fontSize: "14px",
    fontWeight: "400",
  }),
};
